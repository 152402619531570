@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;
}

:root {
  --primary-color: #052c65;
}

.fixed {
  position: fixed !important;
}

.bottom-4 {
  bottom: 1rem !important;
}

.right-4 {
  right: 1rem !important;
}