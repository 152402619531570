body,
html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.contact-main-banner {
    width: 100%;
    /* Full viewport width */
    height: 50vh;
    /* Half the viewport height */
    background: url('../../../assets/images/contactus.png') no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-size: cover;
    /* Ensures image covers entire container */
    margin: 0;
    padding: 0;
}

.contact-main-banner-heading h1 {
    color: #fff;
    font-size: 6rem;
    text-align: center;
    margin: 0;
}

/* Responsive styling for smaller screens */
@media (max-width: 768px) {
    .contact-main-banner {
        height: 40vh;
        /* Adjust height for medium-sized devices */
    }

    .contact-main-banner-heading h1 {
        font-size: 3rem;
        /* Adjust font size for medium devices */
    }
}

@media (max-width: 480px) {
    .contact-main-banner {
        height: 30vh;
        /* Adjust height for small devices */
    }

    .contact-main-banner-heading h1 {
        font-size: 2rem;
        /* Adjust font size for small devices */
    }
}