@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.feature-section {
  margin-top: 50px;
  margin-bottom: 50px;
}

.feature-section .feature-title {
  font-size: 28px;
  font-weight: bold;
  color: #000 !important;
}

.feature-highlight {
  color: var(--primary-color);
  font-weight: 800;
}

.feature-underline {
  width: 60px;
  height: 3px;
  background-color: #007bff;
  margin: 0 auto 20px auto;
}

.feature-item .feature-item {
  margin-top: 30px;
  text-align: left;
}

.feature-icon {
  font-size: 40px;
  color: var(--primary-color);
  margin-bottom: 10px;
}

.feature-section .feature-item h5 {
  font-weight: bold;
}

.feature-section .feature-item p {
  color: #666;
}

.feature-underline {
  height: 4px;
  background-color: #ff9800;
  margin: 0 auto 20px auto;
  width: 80px;
  transition: width 2s ease-in-out;
}