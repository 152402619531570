.contact-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.icon-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 10px;
  border: 2px solid #007bff;
  width: 60px;
  height: 60px;
  transition: transform 0.3s, border-radius 0.3s;
  background-color: white;
}

.icon-circle:hover {
  transform: scale(1.1);
  border-radius: 8px;
}

.icon-style {
  transition: color 0.3s;
  color:#000;
}

.contact-item:hover .icon-style {
  color: #007bff;
}

.active-rectangle {
  border-color: #007bff;
}

.active-rectangle .icon-style {
  color: #007bff;
}

@media (max-width: 576px) {
  .icon-circle {
    width: 50px;
    height: 50px;
  }
}