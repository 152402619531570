.design-banner-container {
  width: 100vw;
  height: 55vh;
  /* Set the height to 50% of the viewport height */
  background: url('../../../../assets/images/desibna.png');
  background-size: contain;
  position: relative;
  display: flex;
  background-size: cover;
  justify-content: center;
  margin-top: -16px;
  align-items: center;
  color: white;
  /* Ensure the text color contrasts with the background */
  min-height: 200px;
  /* Maintain a minimum height for readability */
}

.banner-text-overlay {
  color: white;
  font-size: 6em;
  /* Adjust font size as needed */
  font-weight: bold;
  text-align: center;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
  z-index: 1;
}