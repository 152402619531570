.About-Content {
    padding: 40px 0;
    background-color: #f9f9f9;
}

.span {
    color: var(--primary-color);
    font-weight: 900;
}

.About-Content h4 {
    font-size: 2rem;
    margin-bottom: 20px;
}

.img-container {
    text-align: center;
}

.img-container img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    transition: transform 0.3s ease;
}

.img-container img:hover {
    transform: scale(1.05);
}

.content {
    font-size: 1rem;
    color: #333;
    text-align: justify;
    padding: 15px;
}

/* Responsive Adjustments */
@media (min-width: 768px) {
    .About-Content {
        padding: 60px 0;
    }

    .content {
        padding-right: 20px;
    }
}