/* General Footer Styling */
.footer {
    padding: 2rem 0;
    background-color: #052c65;
    /* Primary color */
}

/* Footer Logo */
.footer-logo {
    width: 80px;
    height: 80px;
    margin-bottom: 1rem;
    /* Ensures some spacing below the logo */
}

/* Useful Links */
.useful-links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* Aligns links to the left */
}

/* Shared container styling */
.company,
.useful-links,
.contact-us,
.map {
    height: 100%;
    margin-bottom: 1.5rem;
}

/* Headings Styling */
.useful-links h2,
.company h2,
.contact-us h2,
.map h2 {
    margin-bottom: 1rem;
    font-size: 20px;
    font-weight: 900;
    text-align: left;
    /* Aligns text to the left */
}

/* Link Styling */
.useful-links a {
    color: inherit;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
}

.useful-links a:hover {
    color: #f8f9fa;
}

.useful-links li {
    margin-bottom: 0.5rem;
}

/* Contact Section Styling */
.contact-us p {
    margin-bottom: 0.5rem;
}

/* Iframe Styling */
iframe {
    width: 100%;
    max-width: 280px;
}

/* Footer Bottom */
.footer-bottom {
    background-color: #041527;
    /* Darker shade for contrast */
    color: #f8f9fa;
}

.footer-bottom-text {
    font-size: 1.1rem;
}

/* Company Section */
.company {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* Aligns logo and text to the left */
    text-align: left;
    /* Ensures text is aligned to the left */
}

/* Logo Image Styling */
.company img {
    margin-bottom: 1rem;
    /* Adds spacing below the image */
    max-width: 100px;
    /* Ensures consistency in size */
    height: auto;
}

/* Hover Effect */
.company h6:hover {
    color: red;
    cursor: pointer;
}

/* List Styling */
.list-unstyled {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

/* Responsive Adjustments */
@media (max-width: 768px) {

    .company,
    .useful-links {
        align-items: flex-start;
        /* Aligns content to the left on smaller screens */
    }

    .useful-links h2 {
        text-align: left;
        /* Aligns heading to the left */
    }

    .company img {
        max-width: 80px;
        /* Adjusts size for smaller screens */
    }

    .footer-bottom-text {
        font-size: 1rem;
        /* Slightly smaller font on smaller screens */
    }

    .list-unstyled {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .sub-container {
        flex-direction: column;
        align-items: flex-start;
    }

    .company,
    .useful-links,
    .contact-us,
    .map {
        align-items: flex-start;
        text-align: left;
    }

    .footer-logo {
        margin: 0 0 1rem 0;
        /* Aligns logo to the left */
    }
}

@media (max-width: 400px) {
    .sub-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .footer {
        padding: 1.5rem 1rem;
    }

    .footer-logo {
        width: 60px;
        height: 60px;
        margin: 0 0 1rem 0;
        /* Ensures alignment to the left */
    }
}