.logo-container {
    padding: 0;
    margin-top: -6px;
    margin-bottom: -2px;
}

.logo-img {
    height: 80px;
    width: 100%;
    object-fit: contain;
}

/* Custom navbar styles */
.custom-navbar {
    padding-top: 0;
    padding-bottom: 0;
}

.ms-auto {
    margin-left: 12px !important;
}

.custom-navbar .nav-item {
    padding: 4px 14px !important;
    margin: 0 16px;
    border: 1px solid #052c65;
    border-radius: 20px;
    color: #000;
    text-align: center;
    transition: background-color 0.3s ease, color 0.3s ease, width 0.3s ease;
    display: inline-block;
    width: auto;
}

.custom-navbar .nav-item:hover {
    background-color: #052c65;
    color: #fff;
    text-decoration: none;
    width: calc(100% + 40px);
}

/* Responsive Styles */
@media (max-width: 768px) {
    .custom-navbar .nav-item {
        padding: 8px 12px;
        /* Increase padding for better touch targets */
        margin: 5px;
        /* Adjust margins for mobile spacing */
        width: 100%;
        /* Full width on mobile */
    }

    .custom-navbar .nav-item:hover {
        width: 100%;
        /* Prevent width expansion on hover for mobile */
    }

    .logo-img {
        height: 60px;
        /* Adjust logo height for mobile */
    }
}