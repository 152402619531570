.project-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem 1rem;
    background: rgb(255, 255, 255);
    overflow: visible; 
    min-height: 100vh; 
}

.projects-heading {
    color: #000000;
    margin-bottom: 20px;
}

.project-heading-h2 {
    color: #000000 !important;
}

.heading-projects-span {
    color: var(--primary-color);
    font-weight: 700;
}

.projects-card {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
    margin: 0 auto;
    
}

/* Updated styles to make project cards equal size */
.project-card {
    background-color: #fff;
    border-radius: 12px;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 30px 30px 40px rgb(0, 0, 0);
    position: relative;
    height: 445px; 
    margin-top: 10px;
}

.project-card:hover .project-img {
    transform: scale(1.09);
    box-shadow: 0px 8px 20px 10px rgba(0, 0, 0, 0.2);
    filter: brightness(0.5);
}
.view-button-overlay {
    position: absolute;
    top: 0px; /* Adjust as needed */
    right: 4px; /* Adjust as needed */
    background: none; /* Remove background */
    border: none; /* Remove border */
    cursor: pointer;
    padding: 0; /* Remove padding */
    color: #f00808; /* Set the icon color */
    font-size: 1.5rem; /* Adjust size as needed */
    transition: color 0.3s; /* Transition for color change */
}

.view-button-overlay:hover {
    color: #0056b3; /* Darker shade on hover */
}
/* Image container */
.project-img-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 200px;
}

/* Image inside card */
.project-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

/* Logo that appears on hover */
.project-logo-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 10;
}

.project-logo {
    width: 80px;
    height: 80px;
    object-fit: contain;
    opacity: 1;
    transition: opacity 0.3s ease;
}

/* Show the logo when hovering over the card */
.project-card:hover .project-logo-container {
    opacity: 1;

}

/* Project description */
.project-des {
    padding: 1rem;
    text-align: center;
    color: #555;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: calc(100% - 200px);
    overflow: hidden;
}

/* Title styling */
.project-des h4 {
    font-size: 1.4rem;
    font-weight: 700;
    color: black;
    margin-bottom: 0.3rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

/* Description styling */
.project-des p {
    font-size: 1rem;
    color: black;
    margin: 0.3rem 0;
}

/* Animations */
.animate-card {
    transform: translateY(0);
    animation: fadeInUp 0.5s ease forwards;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.animate-img {
    opacity: 0;
    animation: fadeIn 0.7s ease forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Text animation for heading */
@keyframes textMoveForwardBackward {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(10px);
    }

    100% {
        transform: translateX(0);
    }
}

/* Custom style for the last project's title */
.last-project-title {
    font-size: 24px; 
    font-weight: bold; 
    color: #fff !important; 
    text-transform: uppercase; 
    letter-spacing: 1.5px; 
    background: linear-gradient(45deg, #ff7a00, #f9e800); 
    color: transparent; 
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3); 
    padding: 10px 15px;
    border-radius: 8px; 
    display: inline-block; 
  }
  
  .last-project-card .project-card {
    height: auto; 
    padding-bottom: 0;
  }
  
  .last-project-card .project-img-container {
    padding-bottom: 0; 
  }
  
  .last-project-card .project-logo-container {
    display: none; 
  }
  