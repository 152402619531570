/* Keyframes for Animations */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Container and Banner Styling */
.banner {
  background-color: var(--primary-color);
  color: var(--text-light);
  width: 100%;
  height: 180px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: background-color 0.3s ease-in-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);

}

.banner-title {
  font-size: 1.8rem;
  font-weight: bold;
  margin: 0;
  animation: fadeInUp 0.8s ease-in-out;
  text-align: center;

}

.banner-image {
  width: 80px;
  height: 80px;
  animation: fadeIn 1s ease-in-out 0.2s;
  transition: transform 0.3s ease-in-out;
}

.banner-image:hover {
  transform: scale(1.1);
}

/* Connect Button Styling */
.connect-btn {
  background-color: #f57b2a !important;
  /* Orange background */
  color: white;
  /* White text */
  padding: 15px 30px;
  /* Increased padding for larger button */
  border: none !important;
  font-size: 1.5rem;
  /* Increased font size */
  font-weight: bold;
  /* Bold text */
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
  outline: none !important;
}



/* Mobile Styles */
@media (max-width: 576px) {
  .banner {
    height: 200px;
  }

  .banner-title {
    font-size: 1.5rem;
  }

  .banner-image {
    width: 60px;
    height: 60px;
  }

  .connect-btn {
    padding: 12px 24px;
    font-size: 1.2rem;
    /* Adjust font size for mobile */
  }
}

/* .banner {
  background: linear-gradient(135deg, #f57b2a, #ffab76);
  color: var(--text-light);

} */

.connect-btn:hover {
  background-color: #e86919 !important;
  /* Darken on hover */
  transform: scale(1.05);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

.banner-image:hover {
  transform: scale(1.15);
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.3));
}

@media (max-width: 768px) {
  .banner-title {
    font-size: 1.6rem;
  }

  .connect-btn {
    padding: 14px 28px;
    font-size: 1.3rem;
  }
}

@media (max-width: 480px) {
  .banner-title {
    font-size: 1.4rem;
  }

  .connect-btn {
    padding: 12px 20px;
    font-size: 1.1rem;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}