/* Base styles for the modal */
.custom-service-dropdown .modal-content {
  max-width: 100vw;
  width: 100vw;
  display: flex;
  margin-top: -135px;
  justify-content: center;
  height: 40vh;
}

.custom-service-dropdown .service-dropdown-body {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  overflow: hidden;
  border-radius: 6px;
}

/* Dropdown content container */
.service-dropdown-content {
  display: flex;
  margin-left: 10px;
  gap: 10px;
  flex-wrap: nowrap;
  width: 100%;
}

.service-column {
  flex: 1 1 30%;
  min-width: 180px;
  box-sizing: border-box;
  text-align: center;
}

.service-column h5 {
  font-size: 1.4rem;
  font-weight: 600;
  color: #052c65;
  margin-bottom: 10px;
  cursor: pointer;
}

.service-column ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.service-column li {
  font-size: 0.9rem;
  color: #333;
  margin-bottom: 8px;
}

/* Backdrop styling */
.custom-backdrop {
  background-color: rgba(0, 0, 0, 0.6);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .custom-service-dropdown .modal-content {
    height: auto;
    margin-top: 0;
  }

  .service-dropdown-content {
    flex-wrap: wrap;
    gap: 20px;
  }

  .service-column {
    flex: 1 1 100%;
    min-width: unset;
  }

  .service-column h5 {
    font-size: 1.2rem;
  }

  .service-column li {
    font-size: 0.85rem;
  }
}

@media (max-width: 480px) {
  .custom-service-dropdown .modal-content {
    margin-top: 0;
    padding: 10px;
    margin-right: 10px;
    margin-left: 10px;
  }

  .service-column h5 {
    font-size: 1rem;
  }

  .service-column li {
    font-size: 0.8rem;
  }
}
