/* Reduce the space between the header and carousel */
.custom-carousel {
    margin-top: -20px;
    /* Adjust the value as needed */
}

/* Rest of your code remains the same */
.carousel-image {
    width: 100%;
    height: auto;
    max-height: 495px;
    object-fit: cover;
    display: block;
    margin: 0 auto;
    transition: transform 0.5s ease-in-out, filter 0.5s ease-in-out;
}

.carousel-image::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.3), transparent);
    transition: opacity 0.5s ease, background 2s ease-in-out;
    z-index: 1;
}

.carousel-item {
    overflow: hidden;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
    transition: box-shadow 0.5s ease;
}

.carousel-item:hover {
    box-shadow: 0 15px 25px rgba(2, 1, 46, 0.3);
}

.carousel-item:hover .carousel-image::before {
    opacity: 1;
    background: linear-gradient(to right, rgba(0, 0, 139, 0.6), rgba(0, 0, 0, 0.1));
}

.carousel-item:hover .carousel-image {
    transform: scale(1.5) rotate(-1deg);
    filter: brightness(60%) saturate(100%);
}

.caption-text {
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out, background 0.5s ease-in-out;
}

.carousel-item:hover .caption-text {
    transform: translateY(-10px);
    opacity: 1;
    background: linear-gradient(to left, rgba(2, 4, 14, 0.9), rgba(150, 155, 224, 0.3));
}

.custom-carousel .carousel-indicator {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 15px;
    width: 100%;
}

.indicator-line {
    display: inline-block;
    width: 12px;
    height: 12px;
    background-color: var(--primary-color);
    margin: 0 6px;
    border-radius: 50%;
    opacity: 0.7;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.indicator-line.active {
    opacity: 1;
    background-color: var(--primary-color);
    transform: scale(1.1);
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.carousel-image {
    animation: fadeIn 1s ease-in-out;
}