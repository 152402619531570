.team-header-heading {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  padding: 10px;
}

.team-header-heading h3 span {
  color: var(--primary-color);
  font-weight: 900;
}

.team-section {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  /* Space between cards horizontally */
  justify-content: center;
  align-items: flex-start;
  margin: 0;
  padding: 0;
}

.team-card {
  flex: 0 1 calc(33.333% - 20px);
  /* Default for large screens (3 cards per row) */
  box-sizing: border-box;
  display: grid;
  align-items: center;
  justify-content: center;
  margin: 0;
  width: 200px;
  /* Default card width */
  height: auto;
  padding: 0;
}

.team-header-heading p {
  font-size: 20px;
}

/* Responsive Design for Tablets */
@media (max-width: 768px) {
  .team-card {
    flex: 0 1 calc(50% - 15px);
    /* Two cards per row on tablets */
  }
}

/* Responsive Design for Mobile Devices */
@media (max-width: 480px) {
  .team-card {
    flex: 0 1 calc(100% - 10px);
    /* One card per row on small screens */
    width: auto;
    /* Adjust width for smaller screens */
  }
}

.team-section .slide {
  width: 300px;
  height: 200px;
  transition: 0.5s;
  background-color: #fff;
}

.slide.slide1 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transition: 0.7s;
  transform: translateY(100px);
}

.team-images {
  width: 140px;
  height: 150px;
}

.team-section .team-card .slide {
  background-color: #fff;
  transition: background-color 0.5s ease;
}

.team-section .team-card:hover .slide.slide1 {
  transform: translateY(0px);
}

.team-section .team-card .slide.slide2 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  transition: 0.8s;
  transform: translateY(-100px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  background-color: #041527;
}

.team-section .team-card:hover .slide.slide2 {
  transform: translateY(0);
}

.team-section .team-card .slide.slide2::after {
  content: "";
  position: absolute;
  width: 30px;
  height: 4px;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
}

.team-section .team-card .slide.slide2 .team-content p {
  margin: 0;
  padding: 0;
  text-align: center;
}

.team-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.team-content p {
  margin: 10px;
  color: #fff;
}

.team-content h2 {
  margin: 10px;
  color: #fff;
}

.team-section .team-card .slide.slide2 .team-content h3 {
  margin: 0 0 10px 0;
  padding: 0;
  font-size: 24px;
  text-align: center;
  color: #414141;
}

.team-section .team-card .slide.slide1 .icon {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.5s;
}

.team-section .team-card .slide.slide2 .team-content {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s;
}

.team-section .team-card:hover .slide.slide2 .team-content {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.5s;
}