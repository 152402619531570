/* Container styling */
.container {
  padding: 20px;
}

/* Static cards styling */
.static-cards .card-front {
  box-shadow: none !important;
  text-align: justify;
}

.static-cards .card-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
}

/* Card container and row spacing */
.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  /* Increased gap for better spacing */
  justify-content: center;
  margin-top: 10px !important;
}

/* Card styling */
.card-wrapper {
  flex: 1 1 calc(20% - 20px);
  /* 5 cards per row with spacing adjustments */
  max-width: 300px;
  min-width: 250px;
  perspective: 1000px;
  margin: 0 2px;
  /* Smaller margin to reduce the space */
  margin-top: 20px !important;
}

.card-inner {
  position: relative;
  width: 100%;
  height: 250px;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.rotatable-card:hover .card-inner {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 250px;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  box-shadow: 1px 2px 2px rgba(61, 61, 61, 0.3);
}

/* Text styling */
.card-front span {
  color: var(--primary-color);
}

.title {
  color: var(--primary-color);
  font-weight: bold;
}

.card-back p {
  text-align: center;
}

.card-front {
  box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, 0.3);
}

.card-front img {
  border-radius: 50%;
  border: 1px solid var(--primary-color);
}

.card-back {
  background-color: var(--primary-color);
  color: #fff;
  transform: rotateY(180deg);
}

.card-front h4 {
  font-size: 32px;
  font-weight: 800;
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .card-wrapper {
    flex: 1 1 calc(25% - 20px);
    /* 4 cards per row */
  }
}

@media (max-width: 992px) {
  .card-wrapper {
    flex: 1 1 calc(33.33% - 20px);
    /* 3 cards per row */
  }
}

@media (max-width: 768px) {
  .card-wrapper {
    flex: 1 1 calc(50% - 20px);
    /* 2 cards per row */
  }

  .static-cards .card-front p {
    display: none;
    /* Hide paragraph */
  }

  .static-cards .card-front h2 {
    font-size: 1.5rem;
    /* Adjust the size of the heading */
    margin-bottom: 15px;
    /* Add margin below the h2 tag */
    text-align: center;
    /* Center-align the h2 text */
  }
}

@media (max-width: 576px) {
  .card-wrapper {
    flex: 1 1 calc(100% - 20px);
    /* 1 card per row */
  }

  .card-front img {
    width: 50px;
    /* Reduce image size */
    height: 50px;
  }

  .card-front h5,
  .card-back h3 {
    font-size: 1rem;
    /* Reduce font size */
  }

  .card-back p {
    font-size: 0.85rem;
    /* Adjust paragraph font size */
  }
}